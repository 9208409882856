// import Home from "./app/home/Home";
import { Route } from 'react-router-dom';
import Sandbox from './utils/form/Sandbox';
import PrivateRoute from './utils/PrivateRoute';
import { Authentication, OnBoarding, Sso, Verify } from './app/onBoarding';
import { ProfileDetails, MyCollections, MySubscription, MyActivity, CollectionDetails, SocialAuth } from './app/profile';
import { Error404, EventDetails, EventPage, Home, SearchBasedLayout, SocialMedia, VideoDetails, ContentResult, AmcListeningPage, ContactUs, TrendingPage, CoBrandingV2, CoBrandingV3 } from './app/home';

import { EVENTS, TARGETEDCONTENT } from './utils/Constant';
import { TargetedContent } from './app/home/staticComponents';
import { NotificationPage, NotificationSettingPage, PrivacyPolicy, TermsConditions } from './app/common/pages';
import { CheckOutPage } from './app/common';
import { CalculatorPage, ToolsPage } from './app/tools';
import { EventCheckOut, MyEvents } from './app/events';
import { Aboutus } from './app/aboutus';
import { Planner } from './app/planner';
import MySocials from './app/profile/MySocials';
import MyPreferences from './app/profile/MyPreferences';
import { Personalization, PersonalizationEditor } from './app/personalization';


const routes = [
  {
    path: ["/onboarding", "/onboarding/:token"],
    name: "OnBoarding",
    type: Route,
    component: OnBoarding
  },

  {
    path: "/auth",
    name: "Authentication",
    type: Route,
    component: Authentication
  },
  {
    path: "/verify/:token",
    name: "Verify",
    type: Route,
    component: Verify
  },
  {
    path: "/",
    name: "Home",
    type: Route,
    component: Home
  },
  {
    path: "/tools",
    name: "ToolsPage",
    type: Route,
    component: ToolsPage
  },
  {
    path: "/tools/calculator/:name",
    name: "ToolsPage",
    type: Route,
    component: CalculatorPage
  },
  {
    path: "/post-planner",
    name: "Planner",
    type: Route,
    component: Planner
  },
  {
    path: "/my-socials",
    name: "MySocials",
    type: PrivateRoute,
    component: MySocials
  },
  {
    path: "/my-preferences",
    name: "MyPreferences",
    type: PrivateRoute,
    component: MyPreferences
  },

  {
    path: ["/content-type/:type", "/search/:type", "/social-media/:type"],
    name: "ContentResult",
    type: Route,
    component: ContentResult
  },
  {
    path: "/social-media",
    name: "SocialMedia",
    type: Route,
    component: SocialMedia
  },
  {
    path: [`/${TARGETEDCONTENT}`, `/${TARGETEDCONTENT}/filter`],
    name: "TargetedContent",
    type: Route,
    component: TargetedContent
  },
  {
    path: "/sso",
    name: "Sso",
    type: Route,
    component: Sso
  },
  // {
  //   path: "/membership-plans",
  //   name: "PricingPlan",
  //   type: Route,
  //   component: PricingPlan
  // },
  {
    path: `/${EVENTS}`,
    name: "EventPage",
    type: Route,
    component: EventPage
  },
  {
    path: `/my-events`,
    name: "MyEvents",
    type: Route,
    component: MyEvents
  },
  {
    path: `/notification`,
    name: "NotificationPage",
    type: Route,
    component: NotificationPage
  },
  {
    path: `/notification-setting/:type`,
    name: "NotificationSettingPage",
    type: Route,
    component: NotificationSettingPage
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    type: Route,
    component: PrivacyPolicy
  },
  {
    path: "/terms-conditions",
    name: "TermsConditions",
    type: Route,
    component: TermsConditions
  },
  {
    path: "/events-details/:id",
    name: "EventDetails",
    type: Route,
    component: EventDetails
  },
  {
    path: "/about-us",
    name: "Aboutus",
    type: Route,
    component: Aboutus
  },
  {
    path: "/event-chekout",
    name: "EventCheckOut",
    type: Route,
    component: EventCheckOut
  },
  {
    path: "/personalization/:personalizedType",
    name: "Personalization",
    type: Route,
    component: Personalization
  },
  {
    path: "/editor/:personalizedType/:id",
    name: "Personalization",
    type: Route,
    component: PersonalizationEditor
  },
  {
    path: "/mycollections",
    name: "MyCollections",
    type: PrivateRoute,
    component: MyCollections
  },

  {
    path: "/details/:type",
    name: "videodetails",
    type: Route,
    component: VideoDetails
  },
  {
    path: "/cobranding",
    name: "CoBranding",
    type: Route,
    // component: CoBranding
    component: CoBrandingV2
    // component: CoBrandingV3
  },
  {
    path: "/cobranding-v2",
    name: "CoBrandingV2",
    type: Route,
    component: CoBrandingV2
  },
  {
    path: "/mymembership",
    name: "MySubscription",
    type: Route,
    // type: PrivateRoute,
    component: MySubscription
  },
  {
    path: "/collection-details/:id",
    name: "CollectionDetails",
    type: Route,
    component: CollectionDetails
  },
  {
    path: "/myactivity/:type",
    name: "MyActivity",
    type: PrivateRoute,
    component: MyActivity
  },
  {
    path: "/profile-details",
    name: "ProfileDetails",
    type: PrivateRoute,
    component: ProfileDetails
  },
  {
    path: "/search-based/:type",
    name: "SearchBasedLayout",
    type: Route,
    component: SearchBasedLayout
  },
  {
    path: "/collection-type/:type",
    name: "AmcListeningPage",
    type: Route,
    component: AmcListeningPage
  },
  {
    path: "/contact",
    name: "ContactUs",
    type: Route,
    component: ContactUs
  },
  {
    path: "/checkout",
    name: "CheckOutPage",
    type: PrivateRoute,
    component: CheckOutPage
  },
  {
    path: "/trending/:trend",
    name: "TrendingPage",
    type: Route,
    component: TrendingPage
  },

  {
    path: "/auth/callback/:platform",
    name: "SocialAuth",
    type: Route,
    component: SocialAuth
  },

  {
    path: "/sandbox",
    name: "Sandox",
    type: Route,
    component: Sandbox
  },

  {
    path: "/*",
    name: "Error404",
    type: Route,
    component: Error404
  },



];
export default routes


