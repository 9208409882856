import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { BsFillCircleFill } from "react-icons/bs";
// const text = ``
export default function PrivacyPolicy() {
  return (
    <div className='page-padding'>
      <Header />
      <div className="container text-center">
        <br />
        <h4 class=' fs-24-18 fw-600'>Privacy Policy</h4>
        <p className="mx-auto mw-900 text-justify py-3 lato-regular fs-16-14 black-242">
          Last Updated: 05 October, 2023 <br /><br />
          Welcome to Thefinpedia, Thefinpedia is formally registered as an entity under the umbrella of Khayalipulav Infosolution LLP.
          Thefinpedia (&quot;the Company,&quot; &quot;we,&quot; &quot;our,&quot; or &quot;us&quot;) is committed to protecting the privacy and security of your personal
          information. This Privacy Policy outlines our practices regarding the collection, use, disclosure, and protection of your
          data when you access or use Thefinpedia (&quot;the Website&quot;).<br /><br />
          By using the Website, you consent to the terms outlined in this Privacy Policy. If you do not agree with the practices
          described herein, please do not use the Website.<br /><br />
          1. Information We Collect<br />
          We may collect both personal and non-personal information from users. The types of information we collect may
          include, but are not limited to:<br />
          <BsFillCircleFill className="desk-circle" /> Personal Information: This includes your name, email address, contact information, and any other information you
          provide when you interact with our services, such as when you subscribe to our newsletter or contact us for
          support.<br />
          <BsFillCircleFill className="desk-circle" /> Log and Usage Data: We collect information related to your use of the Website, including IP addresses, browser
          type, pages viewed, and access times. This data helps us analyze and improve our services.<br />
          <BsFillCircleFill className="desk-circle" /> Cookies and Similar Technologies: We may use cookies, web beacons, and other tracking technologies to enhance
          your experience and understand user behavior. You can manage cookie preferences through your browser settings.<br /><br />
          2. How we collect Information<br />
          We collect personal information that you provide to us when you interact with our services. This may include:
          -Information you provide:<br />
          Registration and Account Information: When you create an account or subscribe to our services, we may collect your
          name, email address, contact information, and other relevant details.<br />
          Communication: We may collect information you provide when you contact us, such as when you send us inquiries,
          feedback, or requests for support.<br />
          Content Submission: If you contribute user-generated content to our platform, such as comments, reviews, or forum
          posts, we collect the information you submit as part of those contributions.<br />
          <BsFillCircleFill className="desk-circle" />Log and Usage Data:<br />
          We automatically collect information related to your use of the Website, including:<br />
          Log Data: This includes technical information such as your IP address, browser type, device information, and access
          times. We use log data to analyze and optimize the performance of our services and troubleshoot issues.<br />
          Usage Data: We collect data related to your interactions with the Website, such as the pages you visit, content you view,
          and features you use. This information helps us understand user behavior and preferences.<br />
          <BsFillCircleFill className="desk-circle" />Cookies and Similar Technologies:<br />
          We may use cookies, web beacons, and other tracking technologies to enhance your experience and gather data about
          your interactions with our Website. Cookies are small text files that are stored on your device. These technologies help
          us with:<br />

          <BsFillCircleFill className="desk-circle" />Authentication: Cookies help us recognize you when you log in and customize your experience based on your
          preferences.<br />
          <BsFillCircleFill className="desk-circle" />Analytics: We use cookies to gather information about how users navigate our Website and to improve its functionality.<br />
          <BsFillCircleFill className="desk-circle" />Marketing: Cookies and similar technologies may be used to deliver personalized content and advertisements.
          You can manage cookie preferences through your browser settings. For more information about how we use cookies
          and similar technologies, please refer to our Cookie Policy.<br />
          Please note that you have the option to disable or block cookies, but doing so may impact your ability to access certain
          features or fully utilize the Website.<br />

          <BsFillCircleFill className="desk-circle" />Information from Third Parties:
          We may receive information from third-party sources (any content creator and media company), including:<br />
          Social Media Platforms: If you choose to connect your social media accounts with our services, we may collect
          information from those platforms in accordance with your privacy settings and their terms of service.<br />
          Our collection of information may vary based on the services you use and how you interact with our Website. We only
          collect and process information that is relevant to the purposes outlined in this Privacy Policy.<br /><br />

          3. How We Use Your Information<br />
          We may use the collected information for various purposes, including:<br />
          <BsFillCircleFill className="desk-circle" /> To provide, maintain, and improve the Website&#39;s functionality and services.<br />
          <BsFillCircleFill className="desk-circle" /> To respond to your inquiries, comments, or requests for support.<br />
          <BsFillCircleFill className="desk-circle" /> To personalize your experience and display content and features tailored to your interests.<br />
          <BsFillCircleFill className="desk-circle" /> To send you email updates, newsletters, and other communications about our services and industry news. You can
          opt out of these communications at any time.<br />
          <BsFillCircleFill className="desk-circle" /> To analyze usage patterns and gather demographic information for research and marketing purposes.<br /><br />

          4. Sharing Your Information<br />
          We do not sell or rent your personal information to third party entities (any content creator and media company).
          However, we may share your information under certain circumstances, including:<br />
          <BsFillCircleFill className="desk-circle" /> With third-party service providers who assist us in delivering and maintaining our services.<br />
          <BsFillCircleFill className="desk-circle" /> When required by law or in response to a valid legal request, such as a court order or government inquiry.<br />
          <BsFillCircleFill className="desk-circle" /> In connection with the sale, merger, or transfer of all or part of our business.<br /><br />

          5. Data Retention<br />
          We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless
          a longer retention period is required or permitted by law. The specific retention periods may vary based on the type of
          information and the context in which it was collected.<br />
          <BsFillCircleFill className="desk-circle" /> Account Information: We retain your account information as long as your account remains active. If you choose to
          deactivate your account, we will retain your information for a reasonable period afterward in case you decide to
          reactivate your account or require assistance with any related services.<br />
          <BsFillCircleFill className="desk-circle" /> Usage and Log Data: Usage and log data are retained for a reasonable period to analyze and improve the
          performance of our services and troubleshoot issues.<br />

          <BsFillCircleFill className="desk-circle" /> Communication Data: If you contact us through email or other communication channels, we will retain the content
          of your communications and our responses for record-keeping and support purposes.<br />
          <BsFillCircleFill className="desk-circle" /> Cookies and Similar Technologies: The retention period for cookies and similar technologies is described in our
          Cookie Policy.<br />
          <BsFillCircleFill className="desk-circle" /> Legal Obligations: We may retain certain information as required to comply with legal obligations, resolve disputes,
          enforce our agreements, or protect our rights.<br />
          Upon reaching the end of the applicable retention period, we will securely delete or anonymize your information in a
          manner that ensures it cannot be reconstructed or associated with you.
          Please note that even after your information is removed from our active systems, it may still exist in archived or backup
          copies for legal, regulatory, or compliance purposes.<br />
          If you have specific questions about data retention or wish to request the deletion of your personal information, please
          contact us at info@thefinpedia.com.<br /><br />
          6. Data Security<br />
          We employ reasonable security measures to protect your personal information from unauthorized access, disclosure,
          alteration, or destruction. However, please be aware that no data transmission or storage method is entirely secure, and
          we cannot guarantee absolute security.<br /><br />
          7. Links to Third-Party Websites<br />
          Our Website may contain links to third-party (any content creator and media company) websites, content, or services
          that are not under our control. We are not responsible for the privacy practices or content of these third-party (any
          content creator and media company) websites. We encourage you to review the privacy policies of any external sites
          you visit.<br /><br />

          8. Children&#39;s Privacy<br />
          The Website is not intended for children under the age of 13. We do not knowingly collect or solicit personal
          information from minors. If you believe that we have collected personal information from a child under 13, please
          contact us, and we will promptly take appropriate measures to delete the information.
          <br /><br />
          9. Changes to this Privacy Policy<br />
          We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or
          regulatory reasons. We will notify you of any material changes by posting a prominent notice on our Website or by
          sending you an email notification.<br /><br />

          10. Your Privacy Rights<br />
          As a user of Thefinpedia, you have certain privacy rights that you can exercise:<br />
          <BsFillCircleFill className="desk-circle" /> Access: You have the right to request access to the personal information we hold about you. Upon your request, we
          will provide you with a copy of your personal data in a structured, commonly used, and machine-readable format.<br />
          <BsFillCircleFill className="desk-circle" /> Correction: You can request that we correct any inaccuracies in your personal information. We strive to keep your
          data accurate and up to date, and your input is essential in achieving this.<br />
          <BsFillCircleFill className="desk-circle" /> Deletion: You have the right to request the deletion of your personal information under certain circumstances, such
          as when it is no longer necessary for the purposes for which it was collected, or if you withdraw your consent.<br />
          <BsFillCircleFill className="desk-circle" /> Objection: You can object to the processing of your personal information for direct marketing purposes or for
          reasons related to your particular situation.<br />

          <BsFillCircleFill className="desk-circle" /> Restriction: You have the right to request the restriction of the processing of your personal information, which
          means we will limit how we use your data in certain situations.<br />
          <BsFillCircleFill className="desk-circle" /> Withdrawal of Consent: If we are processing your personal information based on your consent, you have the right
          to withdraw your consent at any time, without affecting the lawfulness of processing based on consent before its
          withdrawal.<br />
          To exercise any of these rights, please contact us at [Contact Information]. We will respond to your request within a
          reasonable timeframe, subject to any legal or regulatory obligations that may require us to retain certain information.
          Please note that we may request additional information to verify your identity before processing your request.<br />
          We are committed to ensuring that you can exercise your privacy rights effectively and in accordance with applicable
          laws and regulations.<br /><br />
          11. Contact Us<br />
          If you have any questions, concerns, or requests related to this Privacy Policy or the use of your personal information,
          please contact us at info@thefinpedia.com
          <br /><br />
          Effective Date: 05 October, 2023<br />
        </p>


      </div>
      <Footer />
    </div>
  );
}