export const SHARE_BASE_URL = process.env.REACT_APP_DOMAIN_URL;
export const SHARE_RAZ_KEY = process.env.REACT_APP_RAZ_KEY;
export const SITE_KEY = process.env.REACT_APP_SITE_KEY;
export const API_BASE_URL = "api.thefinpedia.com/";
export const API_LIMIT = 18
export const MAX_LIMIT = 18
export const START_PAGE = 1
export const MOBILE_BREAKPOINT = 921

export const SOCIALMEDIA = "social-media";
export const socialMedias = "socialMedias";
export const SOCIAL_MEDIAS = "SocialMedias";
export const SOCIAL_MEDIA = "Social Media";
export const TARGETED_CONTENTS = "TargetedContents";
export const TARGETED_CONTENT = "Targeted Content";
export const GLOBAL_TRENDING = "global-trending";
export const TheFinpedia = "Thefinpedia";

export const PREMIUM = "PREMIUM";
export const PREMIUM_TITLE = "Premium";
export const CUSTOMIZATION = "CUSTOMIZATION";


export const CAMPAIGNS = "Campaigns";
export const CAMPAIGN = "Campaign";

export const LANGUAGES = "Languages";
export const LANGUAGE = "Language";
export const LANGUAGE_Code = "LANGUAGE";

export const isMultiLanguage = "isMultiLanguage";
export const isMultiColor = "isMultiColor";

export const MUTUAL_FUND_COMPANY = "Mutual Fund Company";
export const CREATOR = "creator";
export const CONTENT_CREATORS = "CONTENT_CREATORS";
export const AMC_CONTENT_CREATOR = "amcContentCreator";
export const VIDEOS = "Videos";
export const CAROUSEL = "carousel";
export const EVENTS = "events";
export const INFOGRAPHICS = "Infographics";
export const MINIMALISTS = "Minimalist";
export const PRESENTATIONS = "Presentation";
export const ARTICLESBLOGS = "Articles-Blogs";
export const PODCASTS = "Podcasts";
export const REPORTS = "Reports";
export const EBOOKS = "E-books";
export const AMC = "amc";
export const TARGETEDCONTENT = "targetedContent";
export const TARGETEDCONTENTS = "targetedContents";
export const TARGETED_CONTENTS_NOTIFICATION = "TARGETED_CONTENTS";
export const MYCOLLECTION = "My Collection";
export const EMAILERS = "Emailers";
export const PLATFORMS = "website/platform";
export const SOCIALMEDIA_URL = "website/socialMedia/list";
export const SOCIALMEDIA_URL_DASH = "website/socialMedia/dashboard/list";
export const SORTABLE_URL = "sortable-columns";
export const REGISTER_URL = "/user/register";
export const UPDATE_PROFILE = 'user/update-profile';
export const USER_TRACKING = "user-tracking";
export const USER_COLLECTION = 'user-collection';
export const ORGANIZATION_LOGO = "http://s3.ap-south-1.amazonaws.com/beta.thefinpedia.com/organization-logo/";
export const PROFILE_PIC = "http://s3.ap-south-1.amazonaws.com/beta.thefinpedia.com/profile-pic/";
export const DEFAULT_IMAGE_WIDTH = 450;
export const DEFAULT_IMAGE_HEIGHT = 250;
export const DEFAULT_PUBLISH_DATE = "2001-01-01T00:00:00.000Z";
// ?---------------------- COBRAND CONST START ------------
export const ENDVIDEO = "END-VIDEO";
export const FULLVIDEO = "FULL-VIDEO";
export const STARTVIDEO = "START-VIDEO";
export const VALID_VALUES = [
  "AMC",
  'amc',
  "category",
  "campaigns",
  "languages",
  AMC,
  "targetedContents",

];

export const durationObj = {
  "30": "Monthly",
  "90": "Quarterly",
  "180": "Half Yearly",
  "365": "Yearly",
  '1': 'Daily',
  '0': 'Plan expiry'
};
export const uploadFrequency = [
  { label: "Fortnightly", value: "15.00" },
  { label: "Weekly", value: "7.00" },
  { label: "Bi-Weekly", value: "3.50" },
  { label: "Daily", value: "1.00" },
  { label: "As and When ", value: "0.00" },


];
export const uploadFrequencyManual = [
  { label: "Fortnightly", value: "15.00" },
  { label: "Weekly", value: "7.00" },
  { label: "Bi-Weekly", value: "3.50" },
  { label: "Daily", value: "1.00" }



];

export const uploadFrequencyNew = [
  { label: "Fortnightly", value: "15.00" },
  { label: "Weekly", value: "7.00" },
  { label: "Bi-Weekly", value: "3.50" },
  { label: "Daily", value: "1.00" },
  { label: "As and When ", value: "0.00" },
  { label: "All", value: "20" }

];
export const uploadDuration = {
  "30": "One Month",
  "60": "Two Months",
  "90": "Three Months",
  "180": "Six Months"

};
export const durationObjName = {
  "30": "Month",
  "90": "Quarter",
  "180": "Half Year",
  "365": "Year",
  "1": "Day",
  '0': 'Plan expiry'
}
export const resetInDays = {
  "30": "Monthly Billed",
  "90": "Quarterly Billed",
  "180": "Bi-Annually Billed",
  "365": "Annually Billed"
}
export const durationObjTopupName = {
  "30": "30 Days",
  "90": "90 Days",
  "180": "180 Days",
  "365": "365 Days",
  "1": "1 Day",
  '0': 'Plan expiry'
}
export const CONTENT_AMCS = 'CONTENT_AMCS';
export const SETTING_NOT = {
  'CONTENT_AMCS': 'CONTENT_AMCS',
  'EVENT_AMCS': 'EVENT_AMCS',
  'TARGETED_CONTENTS': 'TARGETED_CONTENTS',
  'EVENT_CREATORS': 'EVENT_CREATORS',
  'CONTENT_CREATORS': 'CONTENT_CREATORS',
  'LOCATIONS': 'LOCATIONS',
};


export const NA = "";
export const PAID = 'Paid';
export const IS_ONLINE = ['Online', 'ONLINE', 'online'];

export const UserTracking = {
  LIKED: "Liked",
  UNLIKE: "Unlike",
  UNSAVE: "Unsave",
  SAVED: "Saved",
  DOWNLOADED: "Downloaded",
  SHARED: "Shared",
  VIEWED: "Viewed",
  SEARCH: "Search",
  Cobrand: 'Cobrand',
}
export const CobrandTypeEnum = {
  TEXT: "TEXT",
  LOGO: "LOGO",
  TEXT_AND_LOGO: "TEXT_AND_LOGO",
}


export const imageClass = {
  VIDEOS: "video-card-height",
  AMC: "video-card-height",
  INFOGRAPHICS: "infographic-image-class",
  POTD: "infographic-image-class",
  MINIMALIST: "podcasts-img-class",
  MINIMALISTS: "podcasts-img-class",
  PRESENTATION: "video-card-height",
  PRESENTATIONS: "video-card-height",
  PODCASTS: "podcasts-img-class",
  CAROUSEL: "podcasts-img-class",
  reports: "infographic-image-class",
  REPORTS: "infographic-image-class",
  ebook: "infographic-image-class",
  EBOOKS: "infographic-image-class",
  "E-BOOKS": "infographic-image-class",
  emailers: "infographic-image-class",
  EMAILERS: "infographic-image-class",
  "ARTICLES": "video-card-height",
  "social-media": " social-media-card-height",
}
// export const ContentType = {
//   VIDEOS: 10,
//   INFOGRAPHICS: 20,
//   MINIMALISTS: 30,
//   PRESENTATIONS: 40,
//   PODCASTS: 50,
//   REPORTS: 60,
//   EBOOKS: 70,
//   EMAILERS: 80,
//   _presentable: {
//     10: "Videos",
//     20: "Infographics",
//     30: "Minimalists",
//     40: "Presentations",
//     50: "Podcasts",
//     60: "Reports",
//     70: "Ebooks",
//     80: "Emailers",
//   },
//   getText: function (value) {
//     return this._presentable[value]
//   }
// }

export const ContentTypeOptions = [
  { option: "All" },
  { option: "Videos" },
  { option: "Infographics" },
  { option: "Minimalists" },
  { option: "Presentations" },
  { option: "Podcasts" },
  { option: "Reports" },
  { option: "E-books" },
]

// export const SORT_ARRAY =['Ascending (A-Z)', 'Descending (Z-A)', "Max Content", "Min Content"];
export const SORT_ARRAY = {
  'Ascending (A-Z)': ["Alphabatically", 'ASC'],
  'Descending (Z-A)': ["Alphabatically", 'DESC'],
  "Max Content": ['Content', 'DESC'],
  "Min Content": ['Content', 'ASC'],
};

export const TopLeft = "TOP-LEFT"
export const TopRight = "TOP-RIGHT"
export const TopCenter = 'TOP-CENTER'
export const CenterLeft = "CENTER-LEFT"
export const CenterCenter = "CENTER-CENTER"
export const CenterRgiht = "CENTER-RIGHT"
export const BottomLeft = "BOTTOM-LEFT"
export const BottomCenter = "BOTTOM-CENTER"
export const BottomRight = "BOTTOM-RIGHT"

export const positionNames = {
  'Top Left': "TOP-LEFT",
  'Top Right': "TOP-RIGHT",
  'Top Center': 'TOP-CENTER',
  'Bottom Left': "BOTTOM-LEFT",
  'Bottom Right': "BOTTOM-RIGHT",
  'Bottom Center': "BOTTOM-CENTER",
  'Center Left': "CENTER-LEFT",
  'Center Center': "CENTER-CENTER",
  'Center Right': "CENTER-RIGHT",
}


export const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}


export const mfObj = {
  monthly: 12,
  daily: 365,
  weekly: 52,
  quarterly: 4,
  halfYearly: 2,
  yearly: 1,
};


export const isCurrentDateAllowed = true;

