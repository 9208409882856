import React, { useState, useEffect } from 'react';
import { Carousel, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { VideoDetailsContentType } from '../home/_utils';

export default function PreviewSlider({ images, previewData }) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [inputValue, setInputValue] = useState('1/1');
    const [totalSlides, setTotalSlides] = useState(0);

    // Update totalSlides whenever images array changes
    useEffect(() => {
        const newTotalSlides = images?.length || 0;
        setTotalSlides(newTotalSlides);
        setInputValue(`${currentSlide + 1}/${newTotalSlides}`);
    }, [images]);

    // Handler for carousel slide change
    const handleSlideChange = (selectedIndex) => {
        setCurrentSlide(selectedIndex);
        setInputValue(`${selectedIndex + 1}/${totalSlides}`); // Update input display
    };

    // Handler for number input change
    const handleInputChange = (e) => {
        const value = e.target.value.split('/')[0];
        setInputValue(`${value}/${totalSlides}`);

        const slideIndex = parseInt(value, 10) - 1;

        // Redirect to the slide if the value is valid
        if (!isNaN(slideIndex) && slideIndex >= 0 && slideIndex < totalSlides) {
            setCurrentSlide(slideIndex);
        }
    };

    // Handle input blur (when user leaves the input field)
    const handleInputBlur = () => {
        const value = parseInt(inputValue.split('/')[0], 10); // Extract only the number
        if (!value || value < 1 || value > totalSlides) {
            // Reset to the current slide if invalid
            setInputValue(`${currentSlide + 1}/${totalSlides}`);
        }
    };

    return (
        <div className="">
            <div className="preview-box  ">
                <Carousel
                    className="planner-slider custom-carousel"
                    interval={3000}
                    indicators
                    controls
                    activeIndex={currentSlide}
                    onSelect={handleSlideChange}
                >
                    {images?.map((ele, i) => (
                        <Carousel.Item key={i}>
                            {ele?.cobrandFilePath && ['mp4', 'mkv', 'avi', 'mov'].includes(
                                ele?.cobrandFilePath.split('.').pop()
                            ) ? (
                                <>
                                    <VideoDetailsContentType
                                        type=""
                                        extUrlLink=""
                                        contentFilePath={ele?.cobrandFilePath}
                                        height="400"
                                    />

                                </>
                            ) : (
                                <Image
                                    className="preview-image d-block w-100"
                                    src={ele?.cobrandFilePath || ele?.filePath} // Assuming each `ele` has a `src` property for the image URL
                                    alt={`Preview ${i + 1}`}
                                    fluid
                                />
                            )}
                        </Carousel.Item>

                    ))}
                </Carousel>
                <p className="preview-text lato-medium fs-12-10 black-242 text-center">
                    {previewData?.caption}
                </p>

                {/* Input box for pagination */}
                <div className="text-center mt-3">
                    <input
                        type="text"
                        id="slider-page-input"
                        value={inputValue}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                        className="form-control d-inline-block"
                        style={{ width: '60px', textAlign: 'center' }}
                    />
                </div>
            </div>
        </div>
    );
}
