import React from 'react'
import Header from './Header'
import Footer from './Footer'

export default function TermsConditions() {
  return (
    <div className='page-padding'>
      <Header />
      <div className="container termsCondition-style">

        <br />
        <h4 class=' fs-24-18 fw-600 text-center'>Terms & Conditions</h4>

        <section>
          <h2 className='text-justify  lato-bold black-242'>Website Description</h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>Welcome to Thefinpedia, The Thefinpedia is formally registered as an entity under the umbrella of Khayalipulav Infosolution LLP. Thefinpedia is a distinguished online platform meticulously crafted exclusively for financial advisors and mutual fund distributors. Our mission is to empower professionals like you with a comprehensive repository of personal finance content. This resource equips you to educate your esteemed clients, elevate your professional stature, and fortify your brand image.</p>
        </section>
        <section id="content-aggregation">
          <h2 className='text-justify  lato-lato-bold black-242'>Content Aggregation</h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>At Thefinpedia, we understand the importance of staying informed in the ever-changing world of finance. That's why we've carefully collected a wide variety of financial content from reputable sources like AMCs and other third-party entities which may include any content creator and media company. Our collection includes educational materials, practical tools, real-time financial news, informative articles, market insights, investment strategies, and more. It is imperative to underscore that our aggregated content comprises two distinct categories:</p>
          <ol>
            <li className='text-justify  lato-regular fs-16-14 black-242'>Content Sourced from Third-Party Entities: We gather content from reputable third-party sources (any content creator and media company) ensuring that our users have access to a wide range of expert insights and knowledge.</li>
            <li className='text-justify  lato-regular fs-16-14 black-242'>Original Content Crafted by Thefinpedia (the Company): In addition to external content, we create and contribute our original content, designed to provide users with unique perspectives and an in-depth understanding of financial matters.</li>
          </ol>
          <p className='text-justify  lato-regular fs-16-14 black-242'>This unified collection seamlessly integrates within our marketplace, offering users a holistic and all-encompassing platform for their pursuit of financial knowledge and insights. Our intention in aggregating this content is to render it easily accessible and discoverable, further enhanced by the inclusion of intelligent features such as our smart search engine, advanced filtering options, and intuitive sorting features. These capabilities aim to empower our users in their quest for pertinent financial information, facilitating informed decisions and fostering a deeper understanding of the intricate financial landscape.</p>
        </section>
        <section id="client-education">
          <h2 className='text-justify  lato-bold  black-242'>Client Education and Brand Enhancement</h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>Our content isn't just for consumption; it's a powerful tool for you to educate your clients and bolster your advisory services. As a financial advisor or mutual fund distributor, you can co-brand and customize select content with your branding materials, ensuring a seamless and personalized experience for your clients. By leveraging our content, you can enhance your credibility and establish yourself as a trusted resource in your clients' financial journeys.</p>
        </section>
        <section id="Information-Decisions">
          <h2 className='text-justify  lato-bold  black-242'>Information for Informed Decisions: </h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>
            It's important to note that the content provided on Thefinpedia is for informational purposes only and
            is not intended as financial advice. While we strive to ensure the accuracy and relevance of the
            information presented, we encourage you to combine our content with your professional expertise.
            Use our resources to educate your clients about financial concepts, investment opportunities, and
            market trends, ultimately helping them make informed decisions. We do not guarantee any data, and
            any action taken based on this is solely at your discretion. </p>
        </section>
        <section id="Join-Community">
          <h2 className='text-justify  lato-bold  black-242'>Join Our Community: </h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>We invite you to actively engage with our content and collaborate with other financial professionals
            within our community. Together, we can foster an environment of continuous learning, networking,
            and growth. By accessing and using Thefinpedia, you agree to abide by our Terms and Conditions, as
            well as our Privacy Policy, which governs the use of this platform, including co-branding and content-
            sharing features. We appreciate your visit and are committed to supporting your journey as a trusted
            financial advisor or mutual fund distributor. </p>
        </section>
        <section id="intellectual-property">
          <h2 className='text-justify   black-242 mb-4'>Intellectual Property</h2>
          <h2 className='text-justify   black-242'>Ownership of Content</h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'> Thefinpedia (the Company) respects and acknowledges the intellectual property rights of the original creators for the content displayed on Thefinpedia (the Website). This content, encompassing text, graphics, logos, images, videos, audio, software, articles, infographics, and social media creatives, may represent the intellectual property of various entities, including asset management companies and third-party entities (any content creator and media company). It is crucial to clarify that all content created by Thefinpedia (the Company) is exclusively owned and copyrighted by Thefinpedia (the Company). Our content is safeguarded by applicable copyright, trademark, patent, and other intellectual property laws. Additionally, we do not claim copyright on the content generated by AMCs or any third-party entities (any content creator and media company) that is uploaded on our website. The intellectual property rights for such content remain with the respective creators. Thefinpedia (the Company) does not engage in the commercialization of content by AMCs or third-party entities, nor do we have any intention to do so in the future. However, in exceptional cases involving bulk co-branding or other exclusive requests by AMCs or third-party entities (any content creator and media company), charges may be applied based on the scope of work and specific arrangements. We maintain transparency in such cases and ensure that any fees or charges are mutually agreed upon and fairly determined.</p>
        </section>
        <section id="Aggregation-Co-Branding">
          <h2 className='text-justify  lato-bold  black-242'>Aggregation and Co-Branding</h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>Thefinpedia serves as an aggregator to make valuable financial content easily discoverable to users.
            Some content provided by AMCs and third-party entities (any content creator and media company)
            may be available for co-branding by users for free. Co-branding allows users to display the content to
            their clients with their branding materials. However, it is important to note that co-branded content
            must not be modified in any way, including the removal or alteration of the original creator's logo,
            branding, or attribution, and users are expressly prohibited from copyrighting or commercializing
            such content. </p>
        </section>
        <section id="Reporting-Concerns">
          <h2 className='text-justify  lato-bold  black-242'>Content Integrity and Reporting Concerns </h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>All the content we aggregate is, to the best of our knowledge, carefully selected and presented. If you
            come across any content on our platform that you believe to be inappropriate, misleading, or in
            violation of our guidelines, we encourage you to report it to us promptly. Your feedback is invaluable
            in ensuring the quality and accuracy of the content we provide. Please contact us at info@Thefinpedia
            to report any concerns or issues regarding the content displayed on Thefinpedia</p>
        </section>
        <section id="Enforcement">
          <h2 className='text-justify  lato-bold  black-242'>Enforcement :</h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>Thefinpedia (the Company) does not claim ownership of third-party (any content creator and media company) content
            but remains committed to upholding the intellectual property rights of content creators. We will
            cooperate with content owners and take necessary actions, including removal of content or suspension
            of user accounts, in response to valid claims of intellectual property infringement. </p>
        </section>

        <section id="pricing-plans">
          <h2 className='text-justify  black-242 mb-4'>Pricing Plans and Subscriptions</h2>
          <h2 className='text-justify  black-242 pb-1'>Overview of Pricing Plans: </h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>Thefinpedia offers several pricing plans and subscription options, each designed to provide users with access to premium features and content. The specific details of each plan, including pricing and features, are available on the Pricing page of our website. Furthermore, it's important to note that each plan delineates specific limits for content/events. It's important to note that if a user doesn't utilize the full extent of their content or event limit within the subscription period and their plan expires, the unused portion of the limit will not carry over to the next subscription period. In other words, any unused content pieces or event allocations do not accumulate or transfer to subsequent subscription periods.</p><br />
          <h2 className='text-justify  black-242 mt-3 pb-1'>Payment and Billing:</h2>

          <p className='text-justify  lato-regular fs-16-14 black-242'> Payment for subscription services is accepted via the payment methods specified on the Pricing page. These methods may include credit cards, debit cards, UPI, or other approved payment methods. Subscriptions are billed on a [Monthly/Quarterly/Half Yearly/Annual] basis, depending on the selected plan. The first payment will be charged on the date of subscription activation, with subsequent payments occurring on the same day of each billing cycle. Users are responsible for ensuring that their payment information remains accurate and up to date. Failure to maintain accurate payment information may result in the suspension or termination of the subscription.</p><br />
          <h2 className='text-justify  black-242 mt-3 pb-1'>Automatic Renewal:</h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'> Some subscription plans may include automatic renewal. If auto-renewal is enabled, your subscription will automatically renew for the same term unless you cancel it before the renewal date. You can manage your subscription settings, including disabling auto-renewal, by accessing your account settings on our website.</p><br />
          <h2 className='text-justify  black-242 mt-3 pb-1'>Cancellations and Refunds:</h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'> You may cancel your subscription at any time. Cancellations can be initiated through your account settings. Refunds will be granted in accordance with our refund policy, if applicable. Please refer to our Refund Policy for detailed information regarding refunds.</p><br />
          <h2 className='text-justify  black-242 mt-3 pb-1'>Modification of Plans:</h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'> Thefinpedia reserves the right to modify or discontinue any pricing plan or subscription option, including changes in pricing, features, or terms, with or without prior notice to users. Users may be notified of such changes via email or through the website. It is the user's responsibility to review plan details and pricing updates.</p><br />
          <h2 className='text-justify  black-242 mt-3 pb-1'>User Responsibilities:</h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>Users are responsible for keeping their payment information up to date and ensuring that there are sufficient funds to cover subscription payments. </p><br />
          <h2 className='text-justify  black-242 mt-3 pb-1'>Refund Policy:</h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>1. Registration Fees for Events: <br />
            There won't be any refund provided if you do not attend your registered event at scheduled time,
            regardless of the circumstances. All registration fees for events on our platform are non-refundable
            under any circumstances.<br /><br />
            2. Subscribed Plans:<br />
            For subscribed plans, no refund will be issued if you cancel the plan before its expiry date and have
            already downloaded any content.<br />
            Refunds will only be considered under the following conditions: <br />
            a. If you cancel the plan within fourteen (14) days of subscribing.  <br />
            b. You have not downloaded or licensed any content during this 14-day period.  <br /><br />

            3. Exception for Special Circumstances: <br />
            We may consider refund requests in exceptional circumstances, such as technical issues preventing
            access to the subscribed plan. Each case will be assessed individually. <br /><br />

            4. Refund Process: <br />
            If you meet the criteria for a refund, we will process it using the original payment method. Refunds
            will be issued for the portion of fees corresponding to the remaining, unused period of your plan only
            in the circumstances mentioned above.<br /><br />

            5. No Refund After 14 Days: <br />
            After the initial fourteen (14) days of subscribing to a plan, no refund requests will be entertained. <br /><br />

            6. Contact Us: <br />
            For refund requests or inquiries, please contact our support team at [info@Thefinpedia.com]. Provide
            relevant details, and our team will assist you accordingly. <br /><br />

            7. No Refund for Violation: <br />
            No refunds will be issued if your account is terminated due to a violation of our terms and conditions. <br />
            Termination:<br />
            Thefinpedia may terminate or suspend a user's subscription in the event of a violation of these Terms
            and Conditions or for other reasons as determined by the Company.<br /><br />
          </p>
          <h2 className='text-justify  black-242 mt-3 pb-4'>Disclaimers:</h2>
          <h2 className='text-justify  black-242 mt-3 pb-1'>Disclaimer of Warranties: </h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>Thefinpedia does not guarantee the Service to be error-free or accessible at all times. We are not liable
            for any downtime caused by external factors, equipment failures, scheduled maintenance, or events
            beyond our control, including but not limited to strikes, riots, natural disasters, and governmental
            actions.</p>
        </section>
        <section id="Disclaimer-third-party">
          <h2 className='text-justify  lato-bold  black-242 pb-1'>Disclaimer for third-party content- </h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>Users are strongly cautioned against relying exclusively on the content available on this website,
            including that from third-party creators or other creators, for financial decision-making. The
            perspectives expressed in third-party content or content by any creator are their own. Although we
            serve as an aggregator for this content and strive for factual accuracy, Thefinpedia does not guarantee
            the precision of material posted by other creators. It is advisable to consult with financial
            professionals before making any financial decisions. </p>
        </section>
        {/* <section id="Join-Community">
          <h2 className='text-justify  lato-bold  black-242'> </h2>
          <p className='text-justify  lato-regular fs-16-14 black-242'>. </p>
        </section> */}
        <section>
          <h2 className='text-justify  black-242'>Contact Information</h2>
          <p className='text-justify  lato-regular fs-16-14 black-242  pb-5'>For inquiries, concerns, or assistance related to subscriptions, please contact our support team at <a href="mailto:info@thefinpedia.com">info@thefinpedia.com</a>.</p>
        </section>
      </div>
      <Footer />
    </div>
  )
}
